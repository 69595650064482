import * as core from '@easyquery/core'
import * as ui from '@easyquery/ui'
import * as enterprise from './dist/lib/public_api.js'

import * as easydata_core from '@easydata/core'
import * as easydata_ui from '@easydata/ui'

var easydata = {
	core: easydata_core,
	ui: easydata_ui
}

export { core, ui, enterprise }

window.easydata = easydata